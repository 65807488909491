var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form',{attrs:{"model":_vm.value,"rules":_vm.rules}},[(_vm.value.event)?_c('div',[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{nativeOn:{"click":function($event){_vm.value.event = null}}},[_vm._v("Actions")]),_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.customEventComponent || _vm.value.event))])],1),_c('el-form-item',{attrs:{"prop":"event"}},[(
            ![
              'goto',
              'capture',
              'startflow',
              'callApi',
              '$display',
              'handover_router',
              'set',
              'form_processor',
              'redirect_bot',
              'azure_queue',
            ].includes(_vm.value.event)
          )?_c('el-autocomplete',{staticClass:"autocomplete-list",attrs:{"fetch-suggestions":_vm.getEventNodes},model:{value:(_vm.value.event),callback:function ($$v) {_vm.$set(_vm.value, "event", $$v)},expression:"value.event"}},[_c('div',{staticStyle:{"width":"40px"},attrs:{"slot":"prepend"},slot:"prepend"},[_vm._v("Action")])]):_vm._e()],1),(_vm.eventComponentIsValid)?_c(_vm.eventComponent,{tag:"component",attrs:{"value":_vm.value,"previous-node":_vm.previousNode}}):_vm._e()],1):_c('div',{staticStyle:{"text-align":"center"}},_vm._l((_vm.eventNodeTypes),function(eventNodeType,index){return _c('el-row',{key:`event-node-selector-button-${index}`,staticStyle:{"padding":"1px auto","margin":"0","text-align":"center"}},[_c('el-button',{staticStyle:{"width":"200px","margin-bottom":"4px"},attrs:{"size":"medium","round":""},on:{"click":function($event){return _vm.setType(eventNodeType.name)}}},[_vm._v(" "+_vm._s(eventNodeType.label)+" ")])],1)}),1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }