<template>
  <el-dialog
    title="Edit Field"
    :visible.sync="dialogVisible"
    width="45%"
    :before-close="handleClose"
    @close="$emit('close')"
  >
    <el-form
      :model="formData"
      label-position="top"
      label-width="100px"
      :rules="rules"
      ref="editForm"
    >
      <el-form-item size="medium" label="Title" prop="title">
        <el-input v-model="formData.title"></el-input>
      </el-form-item>
      <el-form-item size="medium" label="Variable Name" prop="variableName">
        <el-input v-model="formData.variableName"></el-input>
      </el-form-item>
      <el-form-item size="medium" label="Description">
        <el-input v-model="formData.description"></el-input>
      </el-form-item>
      <el-form-item size="medium" label="Default value" v-if="isTextField">
        <el-input v-model="formData.default"> </el-input>
      </el-form-item>
      <el-checkbox v-model="formData.required">Required</el-checkbox>
      <el-checkbox v-model="formData.readonly">Read only</el-checkbox>
      <div v-if="isObjectFieldHasEnumProperty(editTarget, variableName)" style="margin-top: 10px">
        <el-button type="primary" size="small" @click="addEnum">Add Option</el-button>
        <el-form-item
          :label="`Option ${index + 1}`"
          v-for="(item, index) in modalEnumItems"
          :key="index"
          :prop="enumPropFormValidation(index)"
          :rules="[{ required: true, message: 'Please input field option', trigger: 'blur' }, {}]"
        >
          <el-input v-model="modalEnumItems[index]" placeholder="Value" @change="changeEnum(index)">
            <template slot="append">
              <i class="el-icon-delete enum_delete" @click="deleteEnum(index)"></i>
            </template>
          </el-input>
        </el-form-item>
        <div v-if="!isDependencyModal && isDropdownUI && formData.mapArray.length > 0">
          <el-checkbox v-model="formData.isDependencies">Dependencies</el-checkbox>
        </div>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">Cancel</el-button>
      <el-button type="primary" @click="handleSaveChange">Save</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: [
    "form",
    "variableName",
    "dialogVisible",
    "isDependencyModal",
    "editTarget",
    "parentField",
    "setUIOrder",
  ],
  data() {
    const fieldNames = this.form.settings.uiSchema["ui:order"];
    const validateVariableName = (rule, value, callback) => {
      if (value !== this.variableName && fieldNames.includes(value)) {
        callback(new Error("Variable name is already exist."));
      } else {
        callback();
      }
    };

    let dependenciesItems;
    let isDependencies;
    let mapArray;
    let formFieldProperties = _.cloneDeep(this.editTarget.properties[this.variableName]);
    const isRequired = _.includes(this.form.settings.fields.required, this.variableName);
    const formUiSchema = _.cloneDeep(this.form.settings.uiSchema[this.variableName]);

    if (this.isObjectFieldHasEnumProperty(this.editTarget, this.variableName)) {
      const formEnums =
        this.editTarget.properties[this.variableName].enum ||
        this.editTarget.properties[this.variableName].items.enum;
      mapArray = formEnums.map((item) => {
        return {
          name: item,
          oldData: null,
        };
      });
    }

    if (_.has(this.form.settings.fields.dependencies, this.variableName)) {
      dependenciesItems = this.$parent.getParentFieldDependencies(this.variableName);
      mapArray.forEach((item, index) => {
        item.oldData = _.cloneDeep(dependenciesItems[index]);
      });
      isDependencies = true;
    }

    return {
      formData: {
        title: formFieldProperties.title,
        variableName: this.variableName,
        required: isRequired,
        readonly: formUiSchema["ui:readonly"] || false,
        enum: formFieldProperties.enum,
        default: formFieldProperties.default,
        description: formFieldProperties.description,
        isDependencies,
        mapArray,
        items: formFieldProperties.items,
      },
      rules: {
        title: [{ required: true, message: "Please input field label title", trigger: "blur" }],
        variableName: [
          { required: true, message: "Please input field variable name", trigger: "blur" },
          { validator: validateVariableName, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handleClose(done) {
      this.$confirm("Are you sure to close this dialog?")
        .then((_) => {
          this.$emit("close");
        })
        .catch((_) => {});
    },
    handleSaveChange() {
      this.$refs["editForm"].validate((valid) => {
        if (valid) {
          // Change variable name in properties and ui order
          this.$emit("changeVariableName", {
            oldName: this.variableName,
            newName: this.formData.variableName,
            dependencyForm: this.editTarget,
            parentField: this.parentField,
          });

          // Set field properties
          this.setFieldProperties(this.editTarget);
          if (this.formData.default?.length === 0) {
            this.$delete(this.editTarget.properties[this.formData.variableName], "default");
          }

          // Set Dependencies
          if (!this.isDependencyModal) {
            this.setDependencies();
          }

          // Set required
          this.setFieldRequired();

          // Set readonly
          this.$set(
            this.form.settings.uiSchema[this.formData.variableName],
            "ui:readonly",
            this.formData.readonly
          );

          this.$emit("close");
          this.$emit("setUIOrder");

          this.$notify.success({
            title: "Success",
            position: "bottom-right",
            message: `Your change have been saved.`,
          });
        } else {
          return false;
        }
      });
    },

    addEnum() {
      if (!this.isDependencyModal) {
        this.formData.mapArray.push({
          name: "Option " + (this.formData.mapArray.length + 1),
          oldData: null,
        });
      }

      if (this.editTarget.properties[this.variableName].type === "array") {
        this.formData.items.enum.push("Option " + (this.formData.items.enum.length + 1));
        return;
      }
      this.formData.enum.push("Option " + (this.formData.enum.length + 1));
    },
    deleteEnum(index) {
      this.$confirm("Are you sure to delete this property? ", "Confirmation", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        if (!this.isDependencyModal && this.formData.mapArray[index].oldData) {
          const childFields = _.keys(this.formData.mapArray[index].oldData.properties).filter(
            (item) => item !== this.formData.variableName
          );

          childFields.forEach((field) => {
            this.$delete(this.form.settings.uiSchema, field);
          });
        }
        this.formData.mapArray.splice(index, 1);
        if (this.editTarget.properties[this.variableName].type === "array") {
          this.formData.items.enum.splice(index, 1);
          return;
        }
        this.formData.enum.splice(index, 1);
      });
    },
    setDependencies() {
      const data = this.formData.mapArray;
      if (!_.has(this.form.settings.fields, "dependencies")) {
        this.$set(this.form.settings.fields, "dependencies", {});
      }

      if (data && this.formData.isDependencies) {
        let result;
        result = data.map((item) => {
          const oldData = item.oldData ? item.oldData.properties : {};

          if (!_.isEqual(this.variableName, this.formData.variableName)) {
            this.$delete(oldData, this.variableName);
          }

          return {
            properties: {
              ...oldData,
              [this.formData.variableName]: {
                enum: [item.name],
              },
            },
          };
        });

        this.$set(this.form.settings.fields.dependencies, this.formData.variableName, {
          oneOf: result,
        });
      } else {
        this.$delete(this.form.settings.fields.dependencies, this.formData.variableName);
      }
    },
    setFieldProperties(form) {
      const fieldProperties = ["title", "enum", "description", "default", "items"];
      fieldProperties.forEach((item) => {
        let originProperty = form[item];
        const updateProperty = this.formData[item];

        if (!_.isEqual(originProperty, updateProperty)) {
          this.$set(form.properties[this.formData.variableName], item, this.formData[item]);
        }
      });
    },
    setFieldRequired() {
      const index = _.indexOf(this.form.settings.fields.required, this.variableName);
      if (index !== -1) {
        this.form.settings.fields.required.splice(index, 1);
      }

      if (this.formData.required) {
        this.form.settings.fields.required.push(this.formData.variableName);
      }
    },
    isObjectFieldHasEnumProperty(editTarget, variableName) {
      return (
        _.has(editTarget.properties[variableName], "enum") ||
        editTarget.properties[variableName].type === "array"
      );
    },
    changeEnum(index) {
      if (!this.isDependencyModal) {
        this.formData.mapArray[index].name = this.formData.enum[index];
      }
    },
    enumPropFormValidation(index) {
      return this.formData.enum ? `enum[${index}]` : `items.enum[${index}]`;
    },
  },
  computed: {
    isTextField() {
      const isHasUiWidget = _.has(this.form.settings.uiSchema[this.variableName], "ui:widget");
      if (!isHasUiWidget && !this.isObjectFieldHasEnumProperty(this.editTarget, this.variableName))
        return true;
      return false;
    },
    isDropdownUI() {
      const fieldUi = this.form.settings.uiSchema[this.variableName];
      return !_.has(fieldUi, "ui:widget");
    },
    modalEnumItems() {
      return this.formData.enum || this.formData.items.enum;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/colors.scss";

.enum_delete {
  cursor: pointer;
}
.enum_delete:hover {
  color: $color-danger;
}
</style>
