<template>
  <div>
    <el-table v-loading="loading" :data="filteredAutomationList" style="width: 100%">
      <el-table-column prop="name" label="Automation Name">
        <template slot-scope="props">
          <span v-if="props.row.name">
            {{ props.row.name }}
          </span>
          <span v-else>
            <i>No name</i>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="createdAt" label="Created at">
        <template slot-scope="props">
          <span>{{ formatDate(props.row.createdAt, "h:mm a") }}</span>
          <br />
          <span>{{ formatDate(props.row.createdAt, "MMM Do, YYYY") }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="startDate" label="Send Time">
        <template slot-scope="props">
          <div v-if="props.row.scheduleType === AUTOMATION_SCHEDULE_TYPE.NOT_SET">-</div>
          <div
            v-else-if="
              props.row.scheduleType === AUTOMATION_SCHEDULE_TYPE.SEND_NOW && !props.row.sentTime
            "
          >
            -
          </div>
          <div style="display: grid; grid-template-columns: 20px 1fr; grid-gap: 10px" v-else>
            <div style="display: flex; align-items: center">
              <i
                class="el-icon-time time-icon primary"
                v-if="checkSendTime(props.row.startDate)"
              ></i>
              <i
                class="el-icon-time time-icon primary"
                v-else-if="
                  props.row.scheduleType === AUTOMATION_SCHEDULE_TYPE.RECURRING &&
                  checkSendTime(props.row.endDate)
                "
              ></i>
              <i class="el-icon-time time-icon success" v-else></i>
            </div>
            <div v-if="props.row.sentTime">
              <span>{{ formatDate(props.row.sentTime, "h:mm a") }}</span>
              <br />
              <span>{{ formatDate(props.row.sentTime, "MMM Do, YYYY") }}</span>
            </div>
            <div v-else>
              <span>{{ formatDate(props.row.startDate, "h:mm a") }}</span>
              <br />
              <span>{{ formatDate(props.row.startDate, "MMM Do, YYYY") }}</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="Status" label="Status">
        <template slot-scope="props">
          <div v-if="props.row.scheduleType === AUTOMATION_SCHEDULE_TYPE.NOT_SET">Draft</div>
          <div v-else-if="isComplete(props.row)">
            <span style="color: #13ce66">Complete</span>
          </div>
          <div v-else>
            <el-switch
              v-model="props.row.enabled"
              active-color="#1d57d8"
              style="margin-right: 10px"
              @change="changeStatus(props.row)"
            >
            </el-switch>
            <span v-if="props.row.enabled">Enabled</span>
            <span v-else>Disabled</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="Action" label="Action">
        <template slot-scope="props">
          <el-button
            class="automation-list-btn"
            @click="openDetails(props.row)"
            icon="el-icon-info"
            circle
            type="info"
            size="mini"
          >
          </el-button>
          <el-button
            class="automation-list-btn"
            v-if="isComplete(props.row) || !props.row.enabled"
            @click="editAutomation(props.row)"
            icon="el-icon-edit"
            circle
            type="warning"
            size="mini"
          >
          </el-button>
          <el-button
            class="automation-list-btn"
            v-if="canSendNow(props.row)"
            @click="sendNow(props.row)"
            icon="el-icon-s-promotion"
            circle
            type="primary"
            size="mini"
          >
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="automationList.length"
      @current-change="setPage"
      style="padding: 10px 0"
    >
    </el-pagination>

    <el-dialog
      title="Automation Details"
      :visible.sync="automationDetails"
      width="60%"
      :close-on-click-modal="false"
    >
      <el-row :gutter="20">
        <el-col :span="12"> <b>ID</b><br />{{ automation.id }} </el-col>
        <el-col :span="12"> <b>Name</b><br />{{ automation.name }} </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <b>Channels</b><br />
          <div v-for="(item, idx) in automation.channels" :key="'chn' + idx">
            {{ item.name }}
            <span v-if="item.settings && item.settings.endpoint"
              >({{ item.settings.endpoint }})</span
            >
          </div>
        </el-col>
        <el-col :span="12"> <b>Context</b><br />{{ automation.context }} </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <b>Contacts</b><br />
          <div v-if="automation.contactOperations.length > 0">
            OPERATIONS: {{ automation.contactOperations.join(" ") }}
          </div>
          <div v-if="automation.contactStages.length > 0">
            STAGES : [{{ automation.contactStages.join(", ") }}]
            <span v-for="(item, index) in automation.contactStages" :key="'stage' + item.id">
              {{ stagesData[item] }} {{ index === automation.contactStages.length - 1 ? "" : ", " }}
            </span>
          </div>
          <div v-if="automation.contactTags.length > 0">
            TAGS : [{{ automation.contactTags.join(", ") }}]
            <span v-for="(item, index) in automation.contactTags" :key="'tag' + item.id">
              {{ tagsData[item] }} {{ index === automation.contactTags.length - 1 ? "" : ", " }}
            </span>
          </div>
          <div v-if="automation.contactList.length > 0">
            LIST : [{{ automation.contactList.join(", ") }}]
            <span v-for="(item, index) in contactNames" :key="'list' + index">
              {{ item }} {{ index === contactNames.length - 1 ? "" : ", " }}
            </span>
          </div>
          <div v-if="automation.contactAttributes.length > 0">
            VARIABLES :
            <span v-for="(item, index) in automation.contactAttributes" :key="'var' + index">
              [{{ item.attribute }} {{ item.operator }} {{ item.value }}]
              {{ index === automation.contactAttributes.length - 1 ? "" : " AND " }}
            </span>
          </div>
        </el-col>
        <el-col :span="12">
          <b>Messages</b><br />
          <div v-for="(item, index) in automation.messages" :key="'msg' + index">
            <b>#{{ index + 1 }}</b>
            <span v-if="item.contentType === AUTOMATION_MESSAGE_CONTENT_TYPE.WA_MESSAGE_TEMPLATE">
              {{ whatsappTemplates[item.contentId] }}
            </span>
            <span v-else>
              {{ item.contentId }}
            </span>
            <div v-if="item.contentParameters">
              <div v-for="(itm, idx) in item.contentParameters" :key="'p' + index + idx">
                {{ itm.key }} : {{ itm.type }} {{ itm.value }}
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="12">
          <b>Updates</b><br />
          <div v-for="(item, index) in automation.updates" :key="'upd' + index">
            <span v-if="item.failed">[FAILED]</span>
            <span v-else>[SUCCESS]</span>
            {{ item.property }} : 
            <span v-if="item.property === AUTOMATION_OPERATION.ATTRIBUTES">
              <span v-for="(itm, idx) in item.updateItem" :key="'upd'+index+idx">
                [{{ idx }} = {{ itm }}] 
              </span>
            </span>
            <span 
              v-else-if="
                (item.property === AUTOMATION_OPERATION.STAGES || 
                  item.property === OUT_REACH_TAG_TYPE.STAGE) && 
                item.updateTo.length > 0
              "
            >
              {{ stagesData[item.updateTo[0]] }}
            </span>
            <span v-else>
              {{ item.method }}
              <span v-for="(itm, idx) in item.updateTo" :key="'upd' + index + idx">
                {{ tagsData[itm] }} {{ index === item.updateTo.length - 1 ? "" : ", " }}
              </span>
            </span>
          </div>
        </el-col>
        <el-col :span="12">
          <b>Schedule</b><br />
          <div>TYPE : {{ automation.scheduleType }}</div>
          <div v-if="automation.scheduleType === AUTOMATION_SCHEDULE_TYPE.SCHEDULED">
            SCHEDULED: {{ formatDate(automation.startDate, "D MMM YYYY, h:mm a ") }}
          </div>
          <div v-else-if="automation.scheduleType === AUTOMATION_SCHEDULE_TYPE.RECURRING">
            <div>
              START :
              {{
                automation.startDate ? formatDate(automation.startDate, "D MMM YYYY, h:mm a ") : ""
              }}
            </div>
            <div>
              END :
              {{ automation.endDate ? formatDate(automation.endDate, "D MMM YYYY, h:mm a ") : "" }}
            </div>
            <div>SUMMARY : {{ sendOutSummary }}</div>
          </div>
        </el-col>
      </el-row>

      <div>
        <b>Contacts</b>
      </div>
      <el-table v-loading="contactLoading" :data="automationContacts">
        <el-table-column prop="id" label="ID"></el-table-column>
        <el-table-column prop="name" label="Name"></el-table-column>
        <el-table-column prop="primaryContact" label="Primary Contact"></el-table-column>
        <el-table-column prop="country" label="Country"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import gql from "graphql-tag";
import _ from "lodash";
import moment from "moment";
import {
  AUTOMATION_SCHEDULE_TYPE,
  AUTOMATION_CHANNEL_NAME,
  AUTOMATION_MESSAGE_CONTENT_TYPE,
  AUTOMATION_OPERATION,
  OUT_REACH_TAG_TYPE,
} from "@/store/modules/outreach/types";

export default {
  components: {},
  data() {
    return {
      AUTOMATION_SCHEDULE_TYPE,
      AUTOMATION_MESSAGE_CONTENT_TYPE,
      AUTOMATION_OPERATION,
      OUT_REACH_TAG_TYPE,
      automation: {
        id: null,
        name: "",
        context: "",
        enabled: false,
        channels: [],
        messages: [],
        contactTags: [],
        contactStages: [],
        contactList: [],
        contactAttributes: [],
        contactOperations: [],
        scheduleType: AUTOMATION_SCHEDULE_TYPE.NOT_SET,
        scheduleCron: [],
        startDate: null,
        endDate: null,
        sentTime: null,
        updates: [],
        createdAt: null,
        updatedAt: null,
      },
      automationList: [],
      automationContacts: [],
      contactNames: [],
      loading: false,
      contactLoading: false,
      page: 1,
      pageSize: 10,
      automationDetails: false,
      sendOutSummary: "",
      clientTimezone: moment.tz.guess(),
    };
  },
  computed: {
    filteredAutomationList() {
      return this.automationList.slice(
        this.pageSize * this.page - this.pageSize,
        this.pageSize * this.page
      );
    },
    whatsappTemplates() {
      var templates = this.$store.state.whatsapp.templates || [];

      var items = _.reduce(
        templates,
        function (result, value, key) {
          result[value.id] = value.name;
          return result;
        },
        {}
      );

      return items;
    },
    tagsData() {
      var items = _.reduce(
        this.$store.state.outreach.tags.data,
        function (result, value, key) {
          result[value.id] = value.name;
          return result;
        },
        {}
      );

      return items;
    },
    stagesData() {
      var items = _.reduce(
        this.$store.state.outreach.stages.data,
        function (result, value, key) {
          result[value.id] = value.name;
          return result;
        },
        {}
      );

      return items;
    },
  },
  mounted() {
    this.findAll();
  },
  methods: {
    setPage(val) {
      this.page = val;
    },
    checkSendTime(date) {
      if (date) {
        return moment().isBefore(moment(date));
      } else {
        return true;
      }
    },
    formatDate(date, format) {
      return moment(date).tz(this.clientTimezone).format(format);
    },
    isComplete(item) {
      var flag = false;

      if (item.scheduleType === AUTOMATION_SCHEDULE_TYPE.SEND_NOW && item.sentTime) {
        flag = true;
      } else if (
        item.scheduleType === AUTOMATION_SCHEDULE_TYPE.SCHEDULED &&
        !this.checkSendTime(item.startDate)
      ) {
        flag = true;
      } else if (
        item.scheduleType === AUTOMATION_SCHEDULE_TYPE.RECURRING &&
        !this.checkSendTime(item.endDate)
      ) {
        flag = true;
      }

      return flag;
    },
    canSendNow(item) {
      var flag = false;

      if (
        item.scheduleType === AUTOMATION_SCHEDULE_TYPE.SEND_NOW ||
        item.scheduleType === AUTOMATION_SCHEDULE_TYPE.SCHEDULED
      ) {
        flag = true;
      } else if (
        item.scheduleType === AUTOMATION_SCHEDULE_TYPE.RECURRING &&
        this.checkSendTime(item.endDate)
      ) {
        flag = true;
      }

      return flag;
    },
    sendNow(item) {
      var text = "Send this automation now?";
      this.$confirm(text, "Confirmation", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.onExecute(item.id);
          this.$message({
            message: "Your automation is being sent to the contacts.",
            type: "success",
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async editAutomation(item) {
      if (item.enabled) {
        await this.onDisable(item.id);
      }
      this.$emit("edit", item.id);
    },
    async openDetails(item) {
      var channel = _.find(item.channels, {
        name: AUTOMATION_CHANNEL_NAME.WHATSAPP,
      });

      if (channel) {
        this.fetchWhatsappTemplates(channel.settings.endpoint);
      }

      this.automation = item;
      this.automationDetails = true;
      this.contactLoading = true;

      this.sendOutSummary = "";
      if (this.automation.scheduleCron.length > 1) {
        var startDate = moment(this.automation.startDate);
        _.forEach(this.automation.scheduleCron, function (value, key) {
          var selectedDate = moment(this.automation.startDate);
          selectedDate.date(value[2]);
          selectedDate.month(value[3]);
          if (startDate.isAfter(selectedDate)) {
            selectedDate.add(1, "years");
          }
          if (key === 0) {
            this.sendOutSummary += selectedDate.format("MMMM Do YYYY");
          } else {
            this.sendOutSummary += ", " + selectedDate.format("MMMM Do YYYY");
          }
        });
        this.sendOutSummary += " at " + this.formatDate(this.automation.startDate, "h:mm a");
      } else if (this.automation.scheduleCron.length === 1) {
        var cron = this.automation.scheduleCron[0];
        if (cron[4] !== "*") {
          this.sendOutSummary =
            "Every week on " + this.formatDate(this.automation.startDate, "dddd h:mm a");
        } else if (cron[3] !== "*") {
          this.sendOutSummary =
            "Every year on " + this.formatDate(this.automation.startDate, "MMMM Do h:mm a");
        } else if (cron[2] === "*/2") {
          this.sendOutSummary =
            "Every 2 days at " + this.formatDate(this.automation.startDate, "h:mm a");
        } else if (cron[2] !== "*") {
          this.sendOutSummary =
            "Every month on " + this.formatDate(this.automation.startDate, "Do h:mm a");
        } else {
          this.sendOutSummary =
            "Every day at " + this.formatDate(this.automation.startDate, "h:mm a");
        }

        if (this.automation.endDate) {
          this.sendOutSummary +=
            " till " + this.formatDate(this.automation.endDate, "MMMM Do YYYY, h:mm a");
        } else {
          this.sendOutSummary += " till never";
        }
      }

      await this.getContacts(item.id);
      this.contactNames = [];
      for (let index = 0; index < this.automationContacts.length; index++) {
        if (_.includes(this.automation.contactList, this.automationContacts[index].id)) {
          this.contactNames.push(this.automationContacts[index].name);
        }
      }

      this.contactLoading = false;
    },
    fetchWhatsappTemplates(wa_endpoint) {
      this.$store.dispatch("GET_WHATSAPP_MESSAGE_TEMPLATES", {
        data: { wa_endpoint: wa_endpoint },
      });
    },
    changeStatus(item) {
      var text = "Do you want to disable this automation?";
      if (item.enabled) {
        text = "Do you want to enable this automation?";
      }
      this.$confirm(text, "Confirmation", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.onChangeStatus(item);
        })
        .catch((e) => {
          console.log(e);
          item.enabled = !item.enabled;
        });
    },
    async onChangeStatus(item) {
      if (item.enabled) {
        await this.onEnable(item.id);
        if (item.scheduleType === AUTOMATION_SCHEDULE_TYPE.SEND_NOW) {
          await this.onExecute(item.id);
          this.$message({
            message: "Your automation is now enabled. Messages are being sent to the contacts.",
            type: "success",
          });
        } else {
          this.$message({
            message: "Your automation is now enabled",
            type: "success",
          });
        }
      } else {
        this.onDisable(item.id);
        this.$message({
          message: "Your automation is disabled.",
          type: "warning",
        });
      }
    },
    async findAll() {
      this.loading = true;
      try {
        let response = await this.$apollo.mutate({
          mutation: gql`
            mutation {
              outreachAutomationAPI {
                findAll {
                  id
                  name
                  context
                  enabled
                  channels
                  messages
                  contactTags
                  contactStages
                  contactList
                  contactAttributes
                  contactOperations
                  scheduleType
                  scheduleCron
                  startDate
                  endDate
                  sentTime
                  updates
                  createdAt
                  updatedAt
                }
              }
            }
          `,
        });

        this.automationList = _.get(response, "data.outreachAutomationAPI.findAll", []);
      } catch (error) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async getContacts(id) {
      try {
        let response = await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: String!) {
              outreachAutomationAPI {
                getContacts(id: $id)
              }
            }
          `,
          variables: {
            id: id,
          },
        });

        var data = _.get(response, "data.outreachAutomationAPI.getContacts", []);
        this.automationContacts = data;
      } catch (error) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: error.message,
        });
        this.automationContacts = [];
      }
    },
    async onUpsert(item) {
      try {
        const automation = _.cloneDeep(item);
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($automation: JSON!) {
              outreachAutomationAPI {
                upsert(automation: $automation) {
                  id
                  name
                  channels
                  messages
                  updates
                  startDate
                  endDate
                }
              }
            }
          `,
          variables: {
            automation,
          },
        });
      } catch (error) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: error.message,
        });
      }
    },
    async onEnable(id) {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: String!) {
              outreachAutomationAPI {
                enable(id: $id)
              }
            }
          `,
          variables: {
            id: id,
          },
        });
      } catch (error) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: error.message,
        });
      }
    },
    async onDisable(id) {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: String!) {
              outreachAutomationAPI {
                disable(id: $id)
              }
            }
          `,
          variables: {
            id: id,
          },
        });
      } catch (error) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: error.message,
        });
      }
    },
    async onExecute(id) {
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($id: String!) {
              outreachAutomationAPI {
                execute(id: $id)
              }
            }
          `,
          variables: {
            id: id,
          },
        });
      } catch (error) {
        this.$notify.error({
          title: "Error",
          position: "bottom-right",
          message: error.message,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.tab-campaign .el-table th {
  color: #1d57d8;
  font-weight: 600;
  font-size: 16px !important;
}

.tab-campaign .el-table tr {
  color: #000000;
  font-size: 14px;
}

.tab-campaign .time-icon {
  font-size: 16;
}

.tab-campaign .time-icon.success {
  color: #13ce66;
}

.tab-campaign .time-icon.primary {
  color: #1d57d8;
}

.tab-campaign .automation-list-btn {
  padding: 5px;
  cursor: pointer;
  margin-right: 10px;
  box-sizing: border-box;
  font-size: 16px;
}
</style>
